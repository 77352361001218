<template>
  <div class="about">
    <van-loading color="#0094ff" vertical>加载中...</van-loading>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    let location = window.location.href;
    // let location = "https://zj.zh-chunhui.com/?p_code=Paanqoa"; //
    sessionStorage.setItem("location_url", location);
    // var data = location.split("?")[1].split("&");
    var objs = this.parseHashQueryParameters(location);
    // data.map((item) => (objs[item.split("=")[0]] = item.split("=")[1]));
    this.p_code = objs.p_code.replace(/#\//, '');
    this.getProduct(this.p_code);
  },
  methods: {
    // 年后这个方法要注意
    parseHashQueryParameters(url) {
      // 查找最后一个问号的位置
      const lastIndexOfQuestionMark = url.lastIndexOf("?");
      if (lastIndexOfQuestionMark === -1) {
        return {}; // 如果没有问号，返回空对象
      }
      // 提取最后一个问号后面的查询字符串
      const queryString = url.slice(lastIndexOfQuestionMark + 1);
      // 使用 URLSearchParams 来解析查询字符串（这是处理查询字符串的标准方法）
      const params = new URLSearchParams(queryString);
      const result = {};
      for (const [key, value] of params) {
        result[decodeURIComponent(key)] = decodeURIComponent(value);
      }
      return result;
    },
    // 获取产品
    getProduct(data) {
      let param = {
        p_code: data,
      };
      this.$http
        .post("mapi/promotion/getGoods", param)
        .then((res) => {
          if (res.data.goods.template_web_url == "transferPage") {
            this.$router.push({
              path: "/" + res.data.goods.template_web_url,
              query: {
                p_code: this.p_code,
                c_code: res.data.goods.code,
                phone: this.phoneNumber,
                order_number: res.data.order_number,
              },
            });
          } else {
            this.$router.push({
              path: "/" + res.data.goods.template_web_url,
              query: { p_code: this.p_code },
            });
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang="scss">
.about {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>